import React, { useEffect, useState } from "react";
import './Boxtext.scss';

export const Boxtext = ({ text }) => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        if (index > text.length) return;

        const timeout = setTimeout(() => {
            setIndex(index + 1);
        }, 25);
        
        return () => clearTimeout(timeout);
    }, [index, text]);

    useEffect(() => {
        setIndex(0);
    }, [text])

    return (
        <div className="Boxtext">
            {text.substring(0, index)}
        </div>
    );
};