import React, { useEffect, useState } from "react";
import './InfoBox.scss';

export const InfoBox = () => {
    const [ isOpen, setIsOpen ] = useState(false);
    const [ isDismissed, setIsDismissed ] = useState(false);
    const [ showContent, setShowContent ] = useState(false);

    useEffect(() => {
        if (isOpen) {
            setTimeout(() => {
                setShowContent(isOpen);
            }, 300);
        } else {
            setShowContent(isOpen);
        }
    }, [isOpen]);

    return (
        <div
            className={`InfoBox ${isOpen ? 'open' : 'closed'} ${isDismissed ? 'dismissed' : ''}`}
            onClick={() => setIsOpen(!isOpen)}
        >
            {
                showContent 
                    ? 
                    <>
                        Boxtext uses the OpenAI API which is not free. On average it costs me $0.02 every time the 'Generate' button is pressed. If you find this app enjoyable or useful please consider supporting it so it can stay around! 
                        <a href="https://www.patreon.com/albertjvm" target="_blank" rel="noreferrer">Patreon</a>
                        <button className="InfoBox-dismiss" onClick={() => setIsDismissed(true)}>X</button>
                    </>
                    : '?'
            }
        </div>
    )
};