
import { Boxtext, Button, InfoBox, Pill, PillBar, SnackBar, TextInput } from './components';
import './App.css';
import { useEffect, useState } from 'react';
import { getFirestore, addDoc, updateDoc, collection } from 'firebase/firestore';

function App() {
  const [value, setValue] = useState('');
  const [snack, setSnack] = useState('');
  const [boxtext, setBoxtext] = useState('');
  const [isGenerating, setIsGenerating] = useState(false);
  const [suggestions, setSuggestions] = useState([
    'loading...',
    'loading...',
    'loading...'
  ]);

  const toast = (message) => {
    setSnack(message);

    setTimeout(() => {
        setSnack('');
    }, 2000);
  };

  const getSuggestions = async () => {
    const result = await fetch(
      `https://boxtext.albertjvm.ca/.netlify/functions/prompts`
    );
    const { data: { choices } } = await result.json();
    setSuggestions(choices.map(c => c.text.toLowerCase()));
  };

  const generate = async () => {
    setIsGenerating(true);

    const docRef = await addDoc(
      collection(getFirestore(), 'prompts'),
      {
        prompt: value,
        userAgent: navigator.userAgent,
        time: new Date()
      }
    );

    const result = await fetch(
      `https://boxtext.albertjvm.ca/.netlify/functions/openai?prompt=${value}`
    );
    const { data: { choices } } = await result.json();
    await updateDoc(docRef, {
      boxtext: choices[0]?.text
    });

    setIsGenerating(false);
    setBoxtext(choices[0]?.text);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(boxtext);
    toast('Copied to clipboard');
  };

  useEffect(() => {
    getSuggestions();
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <h1>Boxtext</h1>
      </header>
      <main>
        <h2>Enter prompt</h2>
        <TextInput 
          placeholder="Type anything"
          value={value}
          onChange={setValue}
        />
        <PillBar pills={
          suggestions.map((s, i) => (
            <Pill
              key={`suggestion-${i}`}
              onClick={() => setValue(s)}
            >
              {s}
            </Pill>
          ))
        } />
        <Button
          disabled={!value.length || isGenerating}
          onClick={generate}
        >
          { isGenerating ? 'Generating...' :  'Generate' }
        </Button>
        {boxtext ? 
          <>
            <Boxtext text={boxtext} /> 
            <Button
              onClick={copyToClipboard}
            >
              Copy to clipboard
            </Button>
          </>
        : null}
        <InfoBox />
        <SnackBar snack={snack} />
      </main>
    </div>
  );
}

export default App;
