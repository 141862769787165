import React from "react";
import './Button.scss';

export const Button = ({ 
    children,
    disabled = false,
    onClick
}) => {
    return (
        <button
            className={["Button", disabled ? 'disabled' : null].join(' ')}
            onClick={() => !disabled && onClick()}
        >
            {children}
        </button>
    )
}